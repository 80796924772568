import logo from '../../imgs/white_indian.png'
import { getAuth, sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useRef,useState } from 'react';
import OwnRentSwitches from './OwnRentSwitches';
import LoadingOverlay from '../../Overlays/LoadingOverlay';
import ErrorOverlay from '../../Overlays/ErrorOverlay';
import SuccessOverlay from '../../Overlays/SuccessOverlay';

const TabLogin = () => {

  const [chosenPane, setChosenPane] = useState("Login")
  const [loadingMessage, setLoadingMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [loginError, setLoginError] = useState("")

  const loginFormRef = useRef(null)
  const signUpFormRef = useRef(null)
  const forgotPassFormRef = useRef(null)


const PaneSplitter = () => {
  switch(chosenPane) {
    case "Login": return(<LoginPane />)
    case "SignUp": return(<SignUpPane />)
    case "ForgotPass": return(<ForgotPassPane />)
  }
}

const handlePaneSwitch = (val) => {
  setLoginError("")
  setChosenPane(val)
}


const loginSubmit = async(e) => {
  e.preventDefault()
  const auth = getAuth();
  const email = loginFormRef.current[0].value
  const password = loginFormRef.current[1].value
  setLoadingMessage("Loading..")
  try {
    await signInWithEmailAndPassword(auth, email, password);
    console.log("log in success")
    setLoadingMessage(null);
  } catch (err) {
    setLoadingMessage(null)
    if (err.message == "Firebase: Error (auth/invalid-email)." || err.message == "Firebase: Error (auth/invalid-credential).") {
      setLoginError("Incorrect email or password. Please try again.")
    } else {
      setErrorMessage("Contact Admin!! : ", err.message);
    }
    console.error(err.message)
  }

}

const signUpSubmit = async(e) => {
  e.preventDefault()
  const auth = getAuth();
  const email = signUpFormRef.current[0].value
  const password = signUpFormRef.current[1].value
  console.log(signUpFormRef.current)
  console.log(signUpFormRef.current[0].value)
  console.log(signUpFormRef.current[1].value)
  console.log(signUpFormRef.current[2].value)
  console.log(signUpFormRef.current[3].checked)
  console.log(signUpFormRef.current[4].checked)
  setLoadingMessage("Loading..")
  try {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        console.log(userCredentials)
      })
    console.log("sign up success")
    setLoadingMessage(null);
  } catch (err) {
    if (err.message === "auth/invalid-email") {
      setLoginError("Invalid email format. Please enter a valid email address.");
    } else if (err.message === "auth/email-already-in-use") {
      setLoginError("Email already in use. Please try another email.");
    } else if (err.message === "auth/weak-password") {
      setLoginError("Password is too weak. Please choose a stronger password.");
    } else {
      setErrorMessage("An error occurred: " + err.message);
    }
    console.error(err.message)
    setLoadingMessage(null);
  }
}

const forgotPassSubmit = async (e) => {
  e.preventDefault();
  const auth = getAuth();
  const email = forgotPassFormRef.current[0].value
  setLoadingMessage("Loading..")
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("reset sent")
    setLoadingMessage(null);
    setSuccessMessage("Check your email!")
  } catch (err) {
    if (err.message == "Firebase: Error (auth/invalid-email).") {
      setLoginError("Incorrect email.")
    } else {
      setErrorMessage("Contact Admin!! : ", err.message);
    }
    console.error(err.message)
    setLoadingMessage(null);
  }
};


const LoginPane = () => {
  return (
    <>
      <div style={{height: "10vh"}} />
      <div className='login-form-container'>
        <div style={{display:"flex",justifyContent:"center", width:"100%"}}>
          <img src={logo} style={{filter: "invert(1)", width: "30%"}}></img>
        </div>
        <div className='login-header'>Welcome Back</div>
        <div className='signup-link' style={{marginTop: -20, marginBottom: 10}}>
            Need an account?&nbsp;<div onClick={()=>handlePaneSwitch("SignUp")} style={{cursor:"pointer", color: "#5a4bb7", fontWeight:"bold"}}> Sign up here</div>
          </div>
        <form ref={loginFormRef} className='login-form'>
          <div className='input-group'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' placeholder='login@email.com' required />
          </div>
          
          <div className='input-group'>
            <label htmlFor='password'>Password</label>
            <input type='password' id='password' placeholder='********' required />
          </div>
          <div className='signup-link' style={{marginTop: -10, marginBottom: 10}}>
            Forgot password?&nbsp;<div onClick={()=>handlePaneSwitch("ForgotPass")} style={{cursor:"pointer", color: "#5a4bb7", fontWeight:"bold"}}> Reset password</div>
          </div>
          
          <button type='submit' className='sign-in-button' onClick={loginSubmit}>Sign In</button>
          {loginError && <div className='signup-link' style={{marginTop: 10, marginBottom: -10, color:"#ff0000", fontWeight:"bold"}}>{loginError}</div> }

        </form>
      </div>
    </>
  );
}
const SignUpPane = () => {
    return (
    <>
      <div style={{height: "10vh"}} />
      <div className='login-form-container'>
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>

          <img src={logo} style={{filter: "invert(1)", width: "30%"}}></img>
        </div>
        <div className='login-header'>Create an Account</div>
        
        <form ref={signUpFormRef} className='sign-up-form'>
          <div className='input-group'>
            <label htmlFor='email'>Choose an Email</label>
            <input type='email' id='email' placeholder='login@email.com' required />
          </div>
          
          <div className='input-group'>
            <label htmlFor='password'>Choose a Password</label>
            <input type='password' id='password' placeholder='********' required />
          </div>
          <div className='input-group'>
            <label htmlFor='address'>Choose your Address</label>
            <select style={{width: "100%"}} id='address' placeholder='7 Erica Ln' required >
              <option>7 Erica Ln</option>
            </select>
          </div>
          <OwnRentSwitches/>

          <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
            <button type='submit' className='sign-in-button' onClick={signUpSubmit}>Sign Up</button>
          </div>
          {loginError && <div className='signup-link' style={{marginTop: 10, marginBottom: 10, color:"#ff0000", fontWeight:"bold"}}>{loginError}</div> }
          <div className='signup-link'>
            Return back to&nbsp;<div onClick={()=>handlePaneSwitch("Login")} style={{cursor:"pointer", color: "#5a4bb7", fontWeight:"bold"}}> Login</div>
          </div>
          
        </form>
      </div>
    </>
  );
}
const ForgotPassPane = () => {
  return (
    <>
      <div style={{height: "10vh"}} />
      <div className='login-form-container'>
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
          <img src={logo} style={{filter: "invert(1)", width: "30%"}}></img>
        </div>
        <div className='login-header'>Reset Password</div>
        
        <form ref={forgotPassFormRef} className='forgot-pass-form'>
          <div className='input-group'>
            <label htmlFor='email'>Enter your Email</label>
            <input type='email' id='email' placeholder='login@email.com' required />
          </div>
          <div style={{color:"#000", fontSize: 16}}>An email will be sent to you. Click the link attached to complete a password reset form.</div>
          <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
            <button type='submit' className='sign-in-button' onClick={forgotPassSubmit}>Send Email</button>
          </div>
          <div className='signup-link'>
            Go back to&nbsp;<div onClick={()=>handlePaneSwitch("Login")} style={{cursor:"pointer", color: "#5a4bb7", fontWeight:"bold"}}>Login</div>
          </div>
        </form>
        {loginError && <div className='signup-link' style={{marginTop: 10, marginBottom: -10, color:"#ff0000", fontWeight:"bold"}}>{loginError}</div> }
      </div>
    </>
  );
}

  return(
  <>
    <div className='tab-wrapper' style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <PaneSplitter />
    </div>
    {loadingMessage && <LoadingOverlay loadingMessage={loadingMessage}/>}
    {successMessage && <SuccessOverlay setSuccessMessage={setSuccessMessage} successMessage={successMessage}/>}
    {errorMessage && <ErrorOverlay setErrorMessage={setErrorMessage} errorMessage={errorMessage}/>}
  </>
  )

  
};
export default TabLogin