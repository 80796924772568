import { useState } from "react";

const OwnRentSwitches  = () => {

  const [ownRentStatus, setOwnRentStatus] = useState(null)


  return(
    <div className='input-group' style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly" }}>
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <label htmlFor='owner'>Own</label>
      <input
      style={{transform:"scale(2)"}}
        type='radio'
        id='owner'
        name='ownership'
        value='own'
        required
      />
    </div>
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <label htmlFor='renter'>Rent</label>
      <input
      style={{transform:"scale(2)"}}
        type='radio'
        id='renter'
        name='ownership'
        value='rent'
        required
      />
    </div>
  </div>
  )
}

export default OwnRentSwitches