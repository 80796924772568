const DashboardPlanRoom = () => {

  return (
    <div>
      <div style={{display:"flex",flexDirection:"column"}}>
        <h1>Dashboard PlanRoom</h1>
        <li>launch PlanRoom</li>
        <li>recent rooms</li>
        <li>rooms list</li>
        <li></li>
      </div>
    </div>
  )
}

export default DashboardPlanRoom