const DashboardBudgetBooks = () => {
  return (
    <div>
      <h1>Dashboard BuildBooks</h1>
      <li>launch BuildBooks</li>
      <li>recent documents</li>
      <li>quick-bill</li>
      <li>jobsites</li>
      <li>open invoicing tool</li>
      <li>open bill creator</li>
      <li></li>
    </div>
  );  
}

export default DashboardBudgetBooks