import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { UserDataProvider } from './Contexts/UserDataContext';
import Splash from './Splash/Splash';
import DashboardSplash from './Dash/DashboardSplash'
import Login from './Splash/TabContent/TabLogin'

const App = () => {
  return (
    <BrowserRouter>
      <UserDataProvider>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/dash" element={<DashboardSplash />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </UserDataProvider>
    </BrowserRouter>
  )
}

export default App