import './Overlays.css'


const ErrorOverlay = ({errorMessage, setErrorMessage}) => {
  return (
    <div className="overlay">
    <div className="overlay-content">
      <div style={{fontSize:200, color:"#ff0000", userSelect:"none", fontWeight:"bold"}}>!</div>
      {errorMessage && <div className="overlay-message">{errorMessage}</div>}
      <button onClick={()=>setErrorMessage("")} className="button">Close</button>
    </div>
  </div>
  )

}

export default ErrorOverlay