import "./Dash.css"

const DashboardAdmin = () => {
  return (
    <div className="dash-app">
      <h1>Dashboard Admin</h1>
    </div>
  );
}

export default DashboardAdmin;