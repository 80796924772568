import "./Dash.css"

const DashboardSettings = () => {
  return (
    <div className="bento-box">
      {/* <div className="bento-base b-search">Search Bar</div>
      <div className="bento-base b-search">bento search 2</div> */}
      <div className="bento-base b-large">Profile Picture, Name, etc.</div>
      <div className="bento-base b-medium">Choose a theme.</div>
      <div className="bento-base b-small b-click">Dark Mode / Light Mode</div>
      <div className="bento-base b-small b-click"></div>


      <div className="bento-base b-wide">Notifications. Off / Critical / Tags / All</div>
      <div className="bento-base b-wide">Unit of measurement. Imperial / Metric</div>

      <div className="bento-base b-medium">Subscription Management</div>
      <div className="bento-base b-medium">Payment Options</div>
      <div className="bento-base b-medium">Blocked Users List</div>
      <div className="bento-base b-medium">View Privacy Settings</div>
    </div>
    // <div className="dash-app">
    //   <div style={{display:"flex",flexDirection:"column"}}>
    //     <h1>Dashboard Settings</h1>
    //     <li>Dark / light modes</li>
    //     <li>notifications</li>
    //     <li>theme customization</li>
    //     <li>read privacy settings</li>
    //     <li>subscription management</li>
    //     <li>payment options</li>
    //     <li>blocked users list</li>
    //   </div>
    // </div>
  );
}

export default DashboardSettings