import {useState,useEffect} from 'react'

const DashboardCompany = ({coData,adminOfCompanies}) => {

  useEffect(()=>{
    console.log(coData)
  },[])

  return(
    <div className="bento-box">
      {/* <div className="bento-base b-search">Search Bar</div>
      <div className="bento-base b-search">bento search 2</div> */}
      <div className="bento-base b-large">Company header bento</div>
      <div className="bento-base b-medium">Co-workers online</div>
      <div className="bento-base b-small b-click">View {coData.companyName} Stats</div>
      <div className="bento-base b-small b-click"></div>


      <div className="bento-base b-tall">calendar</div>
      <div className="bento-base b-tall">active jobs (make drag & re-orderable)</div>
      <div className="bento-base b-small">*admin* Update {coData.companyName} info</div>
      <div className="bento-base b-small">*admin* Manage {coData.companyName} permissions</div>
      <div className="bento-base b-small"></div>
      <div className="bento-base b-small"></div>

    </div>
    // <div>
    //   <h1>Dashboard Company</h1>
    //   <li>permissions based bento boxes, most link to BuildBooks.</li>
    //   <li>overall company numbers (cost / income)</li>
    //   <li>list of active jobs (clickable shortcuts to their BB profiles)</li>
    //   <li>active jobs / closed jobs over time (statistic) </li>
    //   <li>upcoming meetings (only ones users are invited to)</li>
    //   <li>upcoming user project deadlines (can mark as complete)</li>
    //   <li>calendar widget (expandable)</li>
    //   <li>*admin* customizable goals</li>
    //   <li></li>
    // </div>
  )
}

export default DashboardCompany