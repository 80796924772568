import './Overlays.css'

const LoadingOverlay = ({loadingMessage}) => {
  return (
    <div className="overlay">
    <div className="overlay-content">
      <div className="spinner"></div>
      {loadingMessage && <div className="overlay-message">{loadingMessage}</div>}
    </div>
  </div>
  )

}

export default LoadingOverlay