import logo from '../imgs/white_indian.png'
import './Splash.css';
import React, {useEffect, useState} from "react";
import TabContact from './TabContent/TabContact'
import TabHome from './TabContent/TabHome'
import TabLogin from './TabContent/TabLogin';
import TabDocuments from './TabContent/TabDocuments';
import Wildlife from './TabContent/TabWildlife'

import { useNavigate } from 'react-router-dom';



function Splash() {

  const [chosenTab, setChosenTab] = useState("Home")
  const navigate = useNavigate()

  const TabSplitter = () => {
    switch (chosenTab) {
      case "Home": return <TabHome />
      case "Docs": return <TabDocuments />
      case "About": return <TabContact />
      case "Login": return <TabLogin />
      case "Wildlife": return <Wildlife />
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div onClick={()=>setChosenTab("Home")} id='TabHome' className='nav-button'>
          <img className='logo-img' src={logo}></img>
          <h3>HOME</h3>
        </div>
        <div onClick={()=>setChosenTab("Docs")} id='TabDocuments' className='nav-button'>
          <h3>DOCS</h3>
        </div>
        <div onClick={()=>setChosenTab("Wildlife")} id='TabWildlife' className='nav-button'>
          <h3>WILDLIFE</h3>
        </div>
        <div onClick={()=>setChosenTab("About")} id='TabContact' className='nav-button'>
          <h3>ABOUT</h3>
        </div>
        <div onClick={()=>{
          navigate("/login")
        }} id='TabLogin' className='nav-button'>
          <h3>LOG IN</h3>
        </div>
      </header>
      
      <div>
        <div className='white-backdrop'></div>
        <main className='mainContent'>
          {chosenTab && <TabSplitter />}
        </main>
      </div>
    </div>
  );
}

export default Splash;
