import './Overlays.css'


const SuccessOverlay = ({successMessage,setSuccessMessage}) => {
  return (
    <div className="overlay">
    <div className="overlay-content">
      <div style={{fontSize:150, color:"#fff", userSelect:"none", fontWeight:"bold"}}>✔</div>
      {successMessage && <div className="overlay-message">{successMessage}</div>}
      <button onClick={()=>setSuccessMessage("")} className="button">Continue</button>
    </div>
  </div>
  )

}

export default SuccessOverlay