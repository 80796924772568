import "./Dash.css"


const DashboardProfile = () => {
  return (
      <div className="bento-box">
        {/* <div className="bento-base b-search">Search Bar</div>
        <div className="bento-base b-search">bento search 2</div> */}
        <div className="bento-base b-large">Profile Picture, Name, etc.</div>
        <div className="bento-base b-medium">set activity status</div>
        <div className="bento-base b-small b-click">Edit Profile / Company</div>
        <div className="bento-base b-small b-click">Change Credentials</div>


        <div className="bento-base b-wide">Public visibility options. Private / Friends only / Anyone</div>
        <div className="bento-base b-wide">bento large</div>
        <div className="bento-base b-wide">bento large</div>
      </div>
  )
}

export default DashboardProfile;