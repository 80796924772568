const DashboardHome = () => {

  return(
    <div>
      <h1>Dashboard Home</h1>
      <li>permissions based bento boxes, most link to BuildBooks.</li>
    </div>
  )
}

export default DashboardHome