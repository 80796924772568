import sunset from '../../imgs/diego_sunset.jpg'

const TabContact = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                <img className='header-image' src={sunset}></img>
            </div>
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>Established 1937</h1>

                
            </div>
        </div>
    )
}
export default TabContact