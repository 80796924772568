import winter from '../../imgs/snow.jpg'

const TabDocuments = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                <img className='header-image' src={winter}></img>
            </div>
                <h1 className='tab-title'>Important Documents</h1>
                <div className='contentBox'>
                    <div className='contentImageBox'>
                        <img className='contentImage' /> 
                    </div>
                    <div className='contentInBox right'>
                        <h3 className='tab-title'>Click to open.</h3>
                        <div>
                            <a href="../../PDFs/owner_handbook.pdf" target="_blank" rel="noopener noreferrer">
                                IPA Home-Owner's Manual
                            </a>
                        </div>
                        <div>
                            <a href="../../PDFs/bylaws.pdf" target="_blank" rel="noopener noreferrer">
                                IPA Bylaws
                            </a>
                        </div>
                        <div>
                            <a href="../../PDFs/community_house_rental_agreement.pdf" target="_blank" rel="noopener noreferrer">
                                Community House Rental Agreement
                            </a>
                        </div>
                        <div>
                            <a href="../../PDFs/newsletter_2024.pdf" target="_blank" rel="noopener noreferrer">
                                2024 Newsletter
                            </a>
                        </div>
                        <div>
                            <a href="../../PDFs/recycling.pdf" target="_blank" rel="noopener noreferrer">
                                Recycling Information
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    )
}
export default TabDocuments