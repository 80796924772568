import "./Dash.css"


const DashboardUsers = ({adminOfCompanies}) => {


	const AdminPanels = () => {
		if (adminOfCompanies.length > 0) {
			return(
				<>
					<div className="bento-base b-medium">*Admin* Manage company permissions</div>
					<div className="bento-base b-small b-click">*Admin* Manage co-workers</div>
					<div className="bento-base b-small">*Admin* Manage / Add company</div>
				</>
			)
		}

	}

	return (
		<div className="bento-box">
			<div className="bento-base b-search">Search for users Bar</div>
			<div className="bento-base b-search">Peers online: (show pfps)</div>
			<div className="bento-base b-tall">Result from user search</div>
			<div className="bento-base b-tall">Profile info of user selected</div>
			{/* <AdminPanels /> */}
		</div>
		// <div className="dash-app">
		// 	<div style={{display:"flex",flexDirection:"column"}}>
		// 		<h1>Manage Users</h1>
		// 		<li>search bar for all users</li>
		// 		<li>profile cards of coworkers (expandable)</li>
		// 		<li>online users, and which projects they're on.</li>	
		// 		<li>*admin* invite user to company</li>
		// 		<li>*admin* manage coworker permissions</li>
		// 		<li>*admin* create company account</li>
		// 	</div>
		// </div>
	)
}

export default DashboardUsers