import wildlife from '../../imgs/wildlife.png'

const TabDocuments = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                <img className='header-image' src={wildlife}></img>
            </div>
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>Wildlife</h1>
                
            </div>
        </div>
    )
}
export default TabDocuments